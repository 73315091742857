<template>
  <div :class="['screen', { 'is-component': isComponent }]">
    <div class="wrap flex-ac flex-jb">
      <div
        v-if="isComponent ? step === 2 : true"
        class="back-icon"
        @click="handleBack"
      >
        <img alt="" src="../assets/images/left-arrow.png" />
      </div>
      <div v-if="step === 1" class="step step1 flex-ac flex-ja">
        <img alt="" class="chat-logo" src="../assets/images/syphon-icon.png" />
        <div class="items">
          <div class="item">
            <div
              class="download-btn flex-ac"
              @click="
                step = 2
                type = 'android'
              "
            >
              <img
                alt=""
                class="btn-icon"
                src="../assets/images/android-icon.png"
              />
              <span>Android</span>
              版本下載
            </div>
          </div>
          <div class="item">
            <div
              class="download-btn flex-ac"
              @click="
                step = 2
                type = 'ios'
              "
            >
              <img
                alt=""
                class="btn-icon"
                src="../assets/images/ios-icon.png"
              />
              <span>iOS</span>
              版本下載
            </div>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="step step2 flex-c wrap">
        <div v-if="type === 'android'" class="item">
          <img
            alt=""
            class="chat-logo"
            src="../assets/images/syphon-icon.png"
          />
          <div
            class="download-btn flex-ac"
            @click="downApp($store.state.syphon.android)"
          >
            <img
              alt=""
              class="btn-icon"
              src="../assets/images/android-icon.png"
            />
            <span>Android</span>
            版本下載
          </div>
          <div class="desc">
            下载 syphon 完成后<br />
            请输入您的<span>帐号全称</span>及密码<br />
            点击 log in 登录
          </div>
          <img
            alt=""
            class="tutorial"
            src="../assets/images/syphon-tutorial.png"
          />
        </div>
        <div v-if="type === 'ios'" class="item">
          <img
            alt=""
            class="chat-logo"
            src="../assets/images/syphon-icon.png"
          />
          <div
            class="download-btn flex-ac"
            @click="downApp($store.state.syphon.ios)"
          >
            <img alt="" class="btn-icon" src="../assets/images/ios-icon.png" />
            <span>iOS</span>
            版本下載
          </div>
          <div class="desc">
            下载 syphon 完成后<br />
            请输入您的<span>帐号全称</span>及密码<br />
            点击 log in 登录
          </div>
          <img
            alt=""
            class="tutorial"
            src="../assets/images/syphon-tutorial.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact_us',
  data() {
    return {
      step: 1,
      type: ''
    }
  },
  props: ['isComponent'],
  components: {},
  mounted() {
  },
  methods: {
    downApp(url) {
      window.location.href = url
    },
    handleBack() {
      if (this.step === 2) {
        this.step = 1
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.screen {
  width: 100vw;
  height: 100vh;
  background: url('../assets/images/login-bg-1.png');
  background-size: cover;

  > .wrap {
    position: relative;
    height: 100%;
    padding-top: 2rem;
    overflow: hidden;
    background-color: #2b2827;
    background-position: top;
    background-size: cover;
    max-width: 45rem;
    margin: 0 auto;

    .step {
      width: 100%;
      display: flex;
      flex-direction: column;

      .chat-logo {
        height: 120px;
        margin: 0 auto 2rem;
      }

      .items {
        display: flex;
        width: 80%;
        justify-content: space-around;

        .item {
          display: flex;
          flex-direction: column;
        }
      }

      &.step2 {
        .item {
          width: 45%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.is-component {
    width: unset;
    background: unset;

    > .wrap {
      padding-top: 0;
      background-color: unset;
    }
  }
}

.back-icon {
  cursor: pointer;

  img {
    width: 1.2rem;
  }

  position: absolute;
  left: 0.2rem;
  top: 0.5rem;
}

/* @media screen and (max-width: 750px) { */
/* } */

.btns {
  margin: 2rem auto;
  width: 90%;
}

.download-btn {
  width: 200px;
  height: 45px;
  color: white;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid #fff;
  border-radius: 12px;
  text-align: center;
  position: relative;
  text-decoration: none;
  padding-left: 50px;
  margin: 1.5rem auto 0;
  line-height: 1.3;
  cursor: pointer;

  .btn-icon {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    line-height: 1.3;
    font-size: 20px;
    margin: 0 0.3rem 0 0.5rem;
  }
}

.desc {
  font-size: 14px;
  color: white;
  line-height: 1.4;
  margin: 1.5rem auto;

  span {
    color: #ca9553;
  }
}

.tutorial {
  width: 80%;
  margin: 0 auto 2rem;
}

@include md {
  .wrap {
    min-height: 100vh;
    height: auto;

    .step {
      flex-wrap: wrap;

      .items {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &.step2 {
        .chat-logo {
          margin-bottom: 1rem;
        }

        .item {
          width: 100% !important;
        }
      }
    }
  }
  .btns {
    width: 100%;
  }
}
</style>
