<template>
  <div class="about-view">
    <div v-if="!!$route.params.type" class="tabs">
      <router-link
        v-for="tab in tabs"
        :key="tab.index"
        :class="['tab', { selected: $route.params.type === tab.name }]"
        :to="`/member_center/about/${tab.name}`"
      >
        <p v-text="tab.label"></p>
      </router-link>
    </div>
    <div v-if="$route.params.type === 'us'" class="content content-1">
      <div class="inner" v-html="info.introduction_tag"></div>
      <img
        v-if="!loading"
        :src="$store.state.site.logoLogin"
        alt=""
        class="logo"
      />
    </div>
    <div v-if="$route.params.type === 'privacy'" class="content content-2">
      <div class="inner" v-html="info.privacy_tag"></div>
      <img
        v-if="!loading"
        :src="$store.state.site.logoLogin"
        alt=""
        class="logo"
      />
    </div>
    <div v-if="$route.params.type === 'agreement'" class="content content-3">
      <div class="inner" v-html="info.contract_tag"></div>
      <img
        v-if="!loading"
        :src="$store.state.site.logoLogin"
        alt=""
        class="logo"
      />
    </div>
    <div v-if="$route.params.type === 'contact'" class="content content-4">
      <contact-us :is-component="true"></contact-us>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import usr from '@/mixins/usr'
import ContactUs from '../contact_us.vue'
import { scrollToTop } from '@/utils'
import tabs from '../about-tabs.json'

export default {
  name: 'about',
  mixins: [usr],
  components: {
    ContactUs
  },
  data() {
    return {
      info: {},
      loading: true,
      tabs
    }
  },
  async created() {
    if (!this.userInfo.token) {
      await this.getGameList()
    }
    const mapStr = JSON.stringify({
      token: localStorage.getItem('token')
    })

    try {
      const { code, data } = await post(
        'getIntroductionInfo.do',
        this.Qs.stringify({ mapStr })
      )
      if (code === 'success') {
        this.info = JSON.parse(data)
      }
    } catch {
    } finally {
      this.loading = false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        scrollToTop()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-view {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 0;

  .tabs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 0.8rem;
    top: 0;
    height: 1.5rem;

    > .tab {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      &.selected {
        color: #b0936f;
        font-size: 0.9rem;
      }
    }
  }

  .content {
    width: 95%;
    margin: 1rem auto 100px;
    color: white;
    text-align: left;
    position: relative;

    ::v-deep .inner {
      margin-top: 1rem;

      h4 {
        color: white;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5;
      }

      p {
        font-size: 0.9rem;
        font-weight: 100;
        line-height: 1.4;
      }
    }

    ::v-deep .logo {
      position: absolute;
      height: 100px;
      bottom: -100px;
      right: 0;
    }
  }
}
</style>
